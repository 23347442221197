import React from 'react';
import ReactDOM from 'react-dom';
import * as Uikit from 'rio-uikit';

if (!React) {
    throw new Error('"rio-session-expired-info" needs a global React on the page');
}

if (!ReactDOM) {
    throw new Error('"rio-session-expired-info" needs a global ReactDOM on the page');
}

const parts = (it) => it.split('.').map((part) => parseInt(part, 10));

const isCompatible = (required, version) => {
    const requiredParts = parts(required);
    const versionParts = parts(version);

    for (let i = 0; i < requiredParts.length; i += 1) {
        if (requiredParts[i] > versionParts[i]) {
            return false;
        }
    }

    return true;
};

const requiredUikit = '0.11.0';
if (!Uikit || !Uikit.VERSION || !isCompatible(requiredUikit, Uikit.VERSION)) {
    // eslint-disable-next-line max-len
    throw new Error(`"rio-session-expired-info" needs a global RioUikit >= ${requiredUikit} on the page but instead got ${(Uikit && Uikit.VERSION)}`);
}

import { SessionExpiredDialog } from './SessionExpiredDialog';

export {
    SessionExpiredDialog,
};
