import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage, IntlProvider } from 'react-intl';

import { InfoDialog } from 'rio-uikit';

import { DEFAULT_LOCALE, getLanguageData, getSupportedLocale } from './lang';

export const SessionExpiredDialog = ({ className, disableEsc, locale, onClose, onConfirm, show }) => {
    const supportedLocale = getSupportedLocale(locale);
    const messages = getLanguageData(supportedLocale);
    return (
        <IntlProvider defaultLocale={DEFAULT_LOCALE} key={supportedLocale} locale={supportedLocale} messages={messages}>
            <InfoDialog
                className={className}
                content={
                    <div className={'text-center'}>
                        <div className={'margin-bottom-25'}>

                            <h2 className={'margin-bottom-25'}>
                                <FormattedMessage id={'heading'} />
                            </h2>
                            <p className={'margin-0 text-size-large'}>
                                <FormattedMessage id={'reason'} />
                            </p>
                            <p className={'margin-0 text-size-large'}>
                                <FormattedMessage id={'whatToDo'} />
                            </p>
                            <p className={'margin-bottom-0 text-size-small'}>
                                <button
                                    className={'btn btn-link btn-link-inline text-size-small'}
                                    onClick={onClose}
                                    type={'button'}
                                >
                                    <FormattedMessage id={'close'} />
                                </button>
                            </p>
                        </div>
                        <a className={'btn btn-primary margin-bottom-20'} href={'/'} onClick={onConfirm}>
                            <FormattedMessage id={'login'} />
                        </a>
                    </div>
                }
                disableEsc={disableEsc}
                onClose={onClose}
                show={show}
            />
        </IntlProvider>
    );
};

SessionExpiredDialog.defaultProps = {
    className: '',
    onConfirm: (ev) => {
        if (ev && ev.preventDefault) {
            ev.preventDefault();
        }

        window.location.reload();
    },
};

SessionExpiredDialog.propTypes = {
    className: PropTypes.string,
    disableEsc: PropTypes.bool,
    locale: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
    onConfirm: PropTypes.func,
    show: PropTypes.bool.isRequired,
};
