import { addLocaleData } from 'react-intl';
import bg from 'react-intl/locale-data/bg';
import cs from 'react-intl/locale-data/cs';
import da from 'react-intl/locale-data/da';
import de from 'react-intl/locale-data/de';
import el from 'react-intl/locale-data/el';
import en from 'react-intl/locale-data/en';
import es from 'react-intl/locale-data/es';
import et from 'react-intl/locale-data/et';
import fi from 'react-intl/locale-data/fi';
import fr from 'react-intl/locale-data/fr';
import hr from 'react-intl/locale-data/hr';
import hu from 'react-intl/locale-data/hu';
import it from 'react-intl/locale-data/it';
import lt from 'react-intl/locale-data/lt';
import lv from 'react-intl/locale-data/lv';
import nb from 'react-intl/locale-data/nb';
import nl from 'react-intl/locale-data/nl';
import pl from 'react-intl/locale-data/pl';
import sl from 'react-intl/locale-data/sl';
//import pt from 'react-intl/locale-data/pt';
import ro from 'react-intl/locale-data/ro';
import sk from 'react-intl/locale-data/sk';
import sv from 'react-intl/locale-data/sv';

import flow from 'lodash/fp/flow';
import has from 'lodash/fp/has';
import head from 'lodash/fp/head';
import map from 'lodash/fp/map';
import split from 'lodash/fp/split';
import zipObject from 'lodash/fp/zipObject';

// Note: If you get weird "function" not defined errors from
// lodash code, you might need to whitelist more
// features to be bundled in webpack.config.js

addLocaleData([
    ...bg,
    ...cs,
    ...da,
    ...de,
    ...el,
    ...en,
    ...es,
    ...et,
    ...fi,
    ...fr,
    ...hr,
    ...hu,
    ...it,
    ...lt,
    ...lv,
    ...nb,
    ...nl,
    ...pl,
    //...pt,
    ...ro,
    ...sk,
    ...sv,
    ...sl,
]);

export const DEFAULT_LOCALE = 'en-GB';

export const nonIsoLocales = [
    'bg-BG',
    'cs-CZ',
    'da-DK',
    'de-DE',
    'el-GR',
    'en-GB',
    'es-ES',
    'et-EE',
    'fi-FI',
    'fr-FR',
    'hr-HR',
    'hu-HU',
    'it-IT',
    'lt-LT',
    'lv-LV',
    'nb-NO',
    'nl-NL',
    'pl-PL',
    //'pt-PT',
    'ro-RO',
    'sk-SK',
    'sv-SE',
    'sl-SI',
];

const baseTranslations = flow(
    // eslint-disable-next-line global-require
    map(locale => require(`./translations/${locale}.json`)),
    zipObject(nonIsoLocales)
)(nonIsoLocales);

export const translations = {
    ...baseTranslations,
    bg: baseTranslations['bg-BG'],
    da: baseTranslations['da-DK'],
    de: baseTranslations['de-DE'],
    el: baseTranslations['el-GR'],
    en: baseTranslations['en-GB'],
    es: baseTranslations['es-ES'],
    et: baseTranslations['et-EE'],
    fi: baseTranslations['fi-FI'],
    fr: baseTranslations['fr-FR'],
    hr: baseTranslations['hr-HR'],
    hu: baseTranslations['hu-HU'],
    it: baseTranslations['it-IT'],
    lt: baseTranslations['lt-LT'],
    lv: baseTranslations['lv-LV'],
    nb: baseTranslations['nb-NO'],
    nl: baseTranslations['nl-NL'],
    pl: baseTranslations['pl-PL'],
    //pt: baseTranslations['pl-PT'],
    ro: baseTranslations['ro-RO'],
    sk: baseTranslations['sk-SK'],
    sv: baseTranslations['sv-SE'],
    sl: baseTranslations['sl-SI'],
};

if (!has(DEFAULT_LOCALE, translations)) {
    // eslint-disable-next-line no-console
    console.warn(
        `The configured default locale "${DEFAULT_LOCALE}" is not present.`
    );
}

const extractLanguage = flow(
    (locale) => locale || DEFAULT_LOCALE,
    split('-'),
    head
);

export const configureLocale = (navigator, lookup) => {

    const getSupportedLocaleFromTranslations = (languageData) => flow(
        (locale) => locale || DEFAULT_LOCALE,
        (locale) => has(locale, languageData) ? locale : extractLanguage(locale),
        (locale) => has(locale, languageData) ? locale : DEFAULT_LOCALE
    );

    return {
        getLanguageData: locale => lookup[locale],
        getSupportedLocale: getSupportedLocaleFromTranslations(lookup),
    };
};

export const { getLanguageData, getSupportedLocale } = configureLocale(navigator, translations);
