(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("React"), require("RioUikit"), require("ReactDOM"));
	else if(typeof define === 'function' && define.amd)
		define(["React", "RioUikit", "ReactDOM"], factory);
	else if(typeof exports === 'object')
		exports["RioSessionExpiredInfo"] = factory(require("React"), require("RioUikit"), require("ReactDOM"));
	else
		root["RioSessionExpiredInfo"] = factory(root["React"], root["RioUikit"], root["ReactDOM"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE__0__, __WEBPACK_EXTERNAL_MODULE__16__, __WEBPACK_EXTERNAL_MODULE__40__) {
return 